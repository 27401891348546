<script>
export default {
  name: 'Affiliation',
  computed: {
    siteURL() {
      return `${this.$env.rootURL}?parrain=${this.$store.getters['auth/user'].god_father_code}`;
    },
    appURL() {
      const parrain = this.$store.getters['auth/user'].god_father_code;
      const { href } = this.$router.resolve({ name: 'register', query: { parrain } });
      return `${this.$env.appURL}${href}`;
    },
  },
};
</script>

<template>
  <section>
    <header class="content has-text-centered">
      <h1 class="is-size-4-mobile">
        Vous aimez Teachizy ?
      </h1>
      <p class="is-size-5 is-size-6-mobile">
        Vous avez envie de nous faire connaître tout en gagnant de l'argent ?
        <br>
        Vous êtes au bon endroit !
      </p>

      <p>
        Découvrez ici les détails de notre programme d'affiliation.
      </p>
    </header>

    <div class="tabs mb-10">
      <ul>
        <router-link tag="li" :to="{name: 'affiliation'}" exact-active-class="is-active">
          <a>Explications</a>
        </router-link>
        <router-link tag="li" :to="{name: 'affiliation_affiliates'}" exact-active-class="is-active">
          <a>Mes affiliés</a>
        </router-link>
        <router-link tag="li" :to="{name: 'affiliation_revenus'}" exact-active-class="is-active">
          <a>Mes revenus</a>
        </router-link>
      </ul>
    </div>

    <router-view />
  </section>
</template>
